export default {
  namespaced: true,
  state: {
    crudLayoutViews: {},
  },
  mutations: {
    setCrudLayoutView(state, {
      routeName,
      selectedView,
    }) {
      state.crudLayoutViews[routeName] = selectedView;
    },
  },
  getters: {
    getSelectedCrudLayoutView: (state) => (routeName) => {
      return state.crudLayoutViews[routeName];
    },
  },
};
