import Vue from 'vue';
import Vuex from 'vuex';

import createPersistedState from 'vuex-persistedstate';

import authorisation from './modules/authorisation';
import error from './modules/error';
import settings from './modules/settings';
import profile from './modules/profile';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: [
        'authorisation',
        'settings',
      ],
    }),
  ],
  modules: {
    authorisation,
    error,
    settings,
    profile,
  },
});
