import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import nl from 'vuetify/es5/locale/nl';

import '@fortawesome/fontawesome-free/css/all.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa',
    values: {
      'close': 'fas fa-times',
      'menu': 'fas fa-bars',
      'delete': 'fas fa-trash-alt',
      'add': 'fas fa-plus',
      'edit': 'fas fa-pencil-alt',
      'search': 'fas fa-search',
      'filter': 'fas fa-filter',
      'list': 'fas fa-list',
      'error': 'fas fa-exclamation-circle',
      'warning': 'fas fa-exclamation-triangle',
      'success': 'fas fa-check-circle',
      'info': 'fas fa-info-circle',
      'bell': 'fas fa-bell',
      'bellSlash': 'fas fa-bell-slash',
    },
  },
  lang: {
    locales: { nl },
    current: 'nl',
  },
  theme: {
    themes: {
      light: {
        primary: '#34a2ab',
        accent: '#34a2ab',
      },
    },
  },
});
