<template>
    <v-app>
        <router-view></router-view>
    </v-app>
</template>

<script lang="js">
export default {
    name: 'App',
};
</script>
